import React from 'react';
import './App.css';
import StockTicker from './StockTicker';
import TopBar from './TopBar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GovTrades from './GovTrades';
import ErrorBoundary from './ErrorBoundary';
import UserReport from './UserReport';
import AiChat from './AiChat';
import Cards from './Cards';
import AppFooter from './AppFooter';
import EcoMarketAnalysis from './EcoMarketAnalysis';
import ContactUs from './ContactUs';
import QuantAlg from './QuantAlg';

function App() {

  return (
    <ErrorBoundary>
        <Router>
            <div className="App">
                <div className="content">
                    <div className="stock-ticker">
                        <StockTicker />
                    </div>
                    <TopBar />
                    <Routes>
                        <Route path='/AiChat' element={<AiChat />} />
                        <Route path='/userReport' element={<UserReport />} />
                        <Route path="/gov-trades" element={<GovTrades />} />
                        <Route path="/eco_market_analysis" element={<EcoMarketAnalysis />} />
                        <Route path="/contactUs" element={<ContactUs />} />
                        <Route path="/QuantAlg" element={<QuantAlg />} />
                    </Routes>
                </div>
            </div>
            <div>
              <Cards />
            </div>
            <AppFooter />
        </Router>
    </ErrorBoundary>
  );
}

export default App;