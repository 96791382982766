import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { AppBar, Toolbar, Typography, Button, Box, Table, TableBody, TableCell, TableContainer, Tooltip , TableHead, TableRow, Paper, useTheme, CircularProgress, Switch, FormControlLabel } from '@mui/material';
import axios from 'axios';

const QuantAlg = () => {
    const [selectedOption, setSelectedOption] = useState('SPmomentum');
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [startDateMomentum, setStartDateMomentum] = useState('2015-01-01');
    const [startDateTrend, setStartDateTrend] = useState('2015-01-01');
    const [ticker, setTicker] = useState('');
    const fixedAppBarWidth = '100%';
    const [error, setError] = useState('')
    const [includeKAMA, setIncludeKAMA] = useState(false);

    const [trendSignals, setTrendSignals] = useState(null);
    const [kamaSignals, setKamaSignals] = useState(null);

    const renderSignals = (signals, signalType = 'Trend Following') => {
      if (!signals) return null; // If no signals, return nothing
    
      const recentSignals = signals.slice(-10).map(signal => ({
        ...signal,
        Date: signal.Date.split('T')[0]
      })).reverse();
        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              {signalType} Signals
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recentSignals.map((signal, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {signal.Date}
                      </TableCell>
                      <TableCell align="right">{signal.action}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        );
    };
  
    const theme = useTheme();
  
    const handleOptionChange = (option) => {
      setSelectedOption(option);
      setData(null); // Clear previous data
    };

    const handleKAMAToggle = (event) => {
      setIncludeKAMA(event.target.checked);
    };
  
    const appBarStyle = {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      boxShadow: 'none',
      borderBottom: `1px solid ${theme.palette.divider}`,
    };
  
    const buttonStyle = {
      color: theme.palette.text.secondary,
      textTransform: 'none',
      marginRight: theme.spacing(2),
    };
  
    const chartStyle = {
      maxWidth: '1200px',
      margin: 'auto',
      display: 'block' // Ensure the image is centered in the div
    };
  
    const toolbarStyle = {
      display: 'flex',
      justifyContent: 'space-between', // This will spread out the items
      alignItems: 'center', // This will vertically center the items
    };
  
    // Style for the buttons' container to center them
    const buttonsContainerStyle = {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center', // This will center the buttons
    };
  
    // Dedicated handlers for date changes
    const handleMomentumDateChange = (event) => setStartDateMomentum(event.target.value);
    const handleTrendDateChange = (event) => setStartDateTrend(event.target.value);
    const handleTickerChange = (event) => setTicker(event.target.value);
  
    const fetchAnalysis = () => {
        setLoading(true);
        let apiUrl = '';
        if (selectedOption === 'SPmomentum') {
            apiUrl = `https://api.easeoffinance.net/api/spmomentum?startDate=${startDateMomentum}`;
        } else if (selectedOption === 'TrendFollowing' && !includeKAMA) {
            apiUrl = `https://api.easeoffinance.net/api/trendfollowing?startDate=${startDateTrend}&ticker=${ticker}`;
        } 
    
        axios.get(apiUrl)
          .then(response => {
            const { data } = response;
            setData(data); // Assuming you still want to keep other data
            setTrendSignals(data.trend_signals ? JSON.parse(data.trend_signals) : null);
            setKamaSignals(data.KAMA_signals ? JSON.parse(data.KAMA_signals) : null);
            setError(''); // Clear any previous error
          })
          .catch(error => {
            console.error('Error:', error);
            setError('Failed to fetch data. Please check the stock ticker and try again.'); // Set error message
            setData(null);
            setTrendSignals(null);
            setKamaSignals(null);
          })
          .finally(() => setLoading(false));
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      fetchAnalysis();
    };
  
    const contentStyle = {
      maxWidth: '1200px',
      margin: 'auto',
    };
  
    return (
      <>
        <AppBar position="static" sx={appBarStyle}>
          <Toolbar sx={toolbarStyle}>
          <Box sx={buttonsContainerStyle}>
              <Typography variant="h6" component="div" padding={2}>
                  Quant Analysis
              </Typography>
              <Button sx={buttonStyle} onClick={() => handleOptionChange('SPmomentum')}>
                S&P Momentum
              </Button>
              <Button sx={buttonStyle} onClick={() => handleOptionChange('TrendFollowing')}>
                Trend Following
              </Button>
          </Box>
          </Toolbar>
        </AppBar>
        <Box style={contentStyle}>
          {/* Form and chart */}
          <Box component="form" m={2} onSubmit={handleSubmit} noValidate>
              {selectedOption === 'SPmomentum' ? (
              <TextField
                  id="date-momentum"
                  label="Start Date for Momentum"
                  type="date"
                  defaultValue={startDateMomentum}
                  onChange={handleMomentumDateChange}
                  sx={{ width: 220, marginRight: theme.spacing(2) }}
                  InputLabelProps={{ shrink: true }}
              />
              ) : (
              <>
                  <TextField
                  id="date-trend"
                  label="Start Date for Trend"
                  type="date"
                  defaultValue={startDateTrend}
                  onChange={handleTrendDateChange}
                  sx={{ width: 220, marginRight: theme.spacing(2) }}
                  InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                  id="ticker"
                  label="Ticker"
                  type="text"
                  value={ticker}
                  onChange={handleTickerChange}
                  sx={{ width: 220 }}
                  />
              </>
              )}
              <Button
              type="submit"
              variant="contained"
              sx={{ height: '56px', marginLeft: theme.spacing(2) }}
              >
              Fetch Data
              </Button>
              {loading && <CircularProgress size={24} sx={{ marginLeft: '10px' }} />} 
              {error && <Typography color="error">{error}</Typography>} 
              {selectedOption === 'TrendFollowing' && (
                
                  <><Tooltip title="Kaufman's Adaptive Moving Average (KAMA) dynamically adjusts to price volatility, offering a more nuanced view of market trends. Including KAMA may help in identifying stronger signals for trend-following strategies." arrow>
                <Typography variant="body2" style={{ color: 'grey', marginBottom: '2%' }}>More on KAMA</Typography>
              </Tooltip><Typography variant="body2" style={{ color: 'grey', marginBottom: '2%' }}>Trend Follow shorter term strategy more successful on volatility stocks</Typography></>
              )}
              {selectedOption === 'SPmomentum' && (
                  <Typography variant="body2" style={{ color: 'grey', marginBottom: '2%' }}>Momentum is a longer term strategy, response time can be up to 30 seconds depending on start date.</Typography>
              )}
          </Box>

          {data && data.chart && (
            <div style={chartStyle}>
              {data && data.chart && (
                  <div style={chartStyle}>
                      <img src={`data:image/png;base64,${data.chart}`} alt="Chart" style={{ width: '100%', height: 'auto' }} />
                      {trendSignals && renderSignals(trendSignals, 'Trend Following')}
                      {kamaSignals && renderSignals(kamaSignals, 'KAMA')}
                  </div>
              )}
            </div>
          )}
        </Box>
      </>
    );
  };
  
  export default QuantAlg;