import React from 'react';
import './error.css'; // Assuming the CSS for the 404 page is in this file

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorInfo: null };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can log the error to an error reporting service
        console.error("Caught by ErrorBoundary:", error, errorInfo);
        // e.g., logErrorToMyService(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // Custom fallback UI - 404 error page
            return (
                <section className="page_404">
                    <div className="container">
                        <div className="row"> 
                            <div className="col-sm-12 ">
                                <div className="col-sm-10 col-sm-offset-1 text-center">
                                    <div className="four_zero_four_bg">
                                        <h1 className="text-center">Error</h1>
                                    </div>
                                    <div className="contant_box_404">
                                        <h3 className="h2">
                                            Look like you're lost
                                        </h3>
                                        <p>the page you are looking for not available!</p>
                                        <a href="/" className="link_404">Go to Home</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;