import React, { useState } from 'react';
import axios from 'axios';
import { Typography, Button, Grid, InputAdornment, TextField } from '@mui/material';
import './aiResponse.css'
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';

function formatResponse(response) {
    const paragraphs = response.split('. ').map((sentence, index) => {
        if (index !== response.split('. ').length - 1) {
            return sentence + '.';
        }
        return sentence;
    });

    return paragraphs.map((paragraph, index) => (
        <Typography key={index} paragraph>
            {paragraph}
        </Typography>
    ));
}


function AiChat() {
    const [ticker, setTicker] = useState('');
    const [chatResponse, setChatResponse] = useState(null);
    const [financialData, setFinancialData] = useState(null);
    const [newsAnalysis, setNewsAnalysis] = useState(null);
    const [loading, setLoading] = useState(false);
  
    const fetchAnalysis = (ticker) => {
        setLoading(true);
        axios.post('https://api.easeoffinance.net/aiChat', { ticker: ticker })
            .then(response => {
                const responseData = response.data;
                setChatResponse(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        fetchAnalysis(ticker);
    };




    return (
        <div style={{ padding: '20px' }}>
            <h1 className="header">AI Stock Analysis</h1>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <TextField
                            label="Enter Stock Ticker"
                            variant="outlined"
                            value={ticker}
                            onChange={(e) => setTicker(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" type="submit">
                            Search
                        </Button>
                        <Typography variant="body2" style={{ color: 'grey', marginBottom: '15px' }}>
                            *Live Fetching Financial Data, Response in about 40 seconds.
                        </Typography>
                    </Grid>
                </Grid>
            </form>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                    <CircularProgress />
                </div>
            ) : (
                <>

                    {chatResponse && (
                        <div className="chatResponseContainer">
                            <Typography variant="h6">AI Chat Response</Typography>
                            <p className="chatResponseText">{formatResponse(chatResponse)}</p>
                        </div>
                    )}

                    {financialData && (
                        <div>
                            <Typography variant="h6">Financial Summary</Typography>
                            <pre>{JSON.stringify(financialData, null, 2)}</pre>
                        </div>
                    )}

                    {newsAnalysis && (
                        <div>
                            <Typography variant="h6">News Analysis</Typography>
                            {newsAnalysis.map((item, index) => (
                                <div key={index}>
                                    <p>Description: {item.description}</p>
                                    <p>Sentiment: {item.sentiment}</p>
                                    <p>Entity Count: {item.entity_count}</p>
                                    <p>Summary Length: {item.summary_length}</p>
                                    <p>Transformer Sentiment: {item.transformer_sentiment}</p>
                                </div>
                            ))}
                        </div>
                      )}
                      </>
                  )}
        </div>
    );
}

export default AiChat;