import React, { useState, useEffect, useRef } from 'react';
import './App.css';

function StockTicker() {
    const [stockData, setStockData] = useState([]);
    const [error, setError] = useState(null);
    const tickerRef = useRef();

    const tickers = [
        'AAPL', 'GOOGL', 'MSFT', 'AMZN', 'JPM', 'JNJ', 'V', 'PG',
        'UNH', 'MA', 'INTC', 'VZ', 'HD', 'T', 'PFE', 'MRK', 'PEP', 'CSCO',
        'BA', 'DIS', 'NFLX', 'NVDA', 'TSLA', 'V', 'WMT',
        'BAC', 'KO', 'XOM', 'CVX', 'ABBV', 'C', 'CMCSA', 'PFE', 'WFC',
        'ACN', 'ADBE', 'AMD', 'AIG', 'ALL', 'GOOG', 'MO', 'AMGN',
        'AXP', 'AIG', 'BMY', 'CAT', 'CL', 'COP', 'COST', 'CVS', 'DHR',
        'DOW', 'DUK', 'EMR', 'EXC', 'F', 'FDX', 'GD'
    ];



    useEffect(() => {
        fetch(`https://api.easeoffinance.net/stock/${tickers.join(',')}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                
                return response.json();
            })
            .then(data => {
                if (Array.isArray(data) && data.length > 0) {
                    setStockData(data);  
                } else {
                    throw new Error("Failed to parse stock data");
                }
            })
            .catch(err => {
                setError(err.message);
            });
    }, []);

    useEffect(() => {
        const updateAnimationVariable = () => {
            if (tickerRef.current) {
                const tickerWidth = tickerRef.current.scrollWidth;
                const rootStyle = document.documentElement.style;
                rootStyle.setProperty('--ticker-end', `-${tickerWidth}px`);
    
                const duration = tickerWidth / 100; // Adjust this ratio as needed
                rootStyle.setProperty('--ticker-duration', `${duration}s`);
            }
        };
        updateAnimationVariable();
        window.addEventListener('resize', updateAnimationVariable);

        return () => window.removeEventListener('resize', updateAnimationVariable);
    }, [stockData]);

    const tickerString = stockData.map((stock, index) => {
        const color = stock.price > stock.previousPrice ? "green" : (stock.price < stock.previousPrice ? "red" : "");
        return (
            <span key={index} className={color}>
                {stock.symbol}: ${stock.price.toFixed(2)} ({stock.percentageChange.toFixed(2)}%)
            </span>
        );
    });

    return error ? (
        <div className="error-message">Error: {error}</div>
    ) : stockData.length === 0 ? 
        <div>Loading...</div> 
    : 
        <div className="ticker-content" ref={tickerRef}>
            {tickerString}
            {tickerString} {/* Duplicate the content for continuous scrolling */}
        </div>;
}

export default StockTicker;