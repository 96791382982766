import React, { useState, useMemo } from 'react';
import axios from 'axios';
import { useTable, usePagination, useSortBy } from 'react-table';
import  Select  from 'react-select';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

function GovTrades() {
    const [name, setName] = useState("");
    const [ticker, setTicker] = useState("");
    const [data, setData] = useState({ trades: [], representatives: [] });
    const [selectedTrade, setSelectedTrade] = useState(null);
    const [nameOptions, setNamesOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleRowClick = (trade) => {
        setSelectedTrade(trade);
    };
    
    const fetchTrades = async () => {
        setLoading(true);
        try {
            const response = await axios.post('https://api.easeoffinance.net/GovTrades', { name, Ticker: ticker });
            if (response.data && typeof response.data === 'object') {
                setData(prevData => ({ ...prevData, ...response.data }));
                const newOptions= response.data.representatives.map(rep => ({ value: rep, label: rep }));
                setNamesOptions(newOptions)

            } else {
                console.error("Unexpected data format:", response.data);
            }
        } catch (error) {
            console.error("API error:", error);
        } finally {
            setLoading(false);
        }
    };
    
    const columns = useMemo(
        () => [
            {
                Header: 'Representative',
                accessor: 'Representative'
            },
            { 
                Header: 'TransactionDate', 
                accessor: 'TransactionDate'
            },
            {
                Header: 'Amount',
                accessor: 'Amount'
            },
            {
                Header: 'Transaction',
                accessor: 'Transaction'
            },
            {
                Header: 'Ticker',
                accessor: 'Ticker'
            },

        ],
        []
        
    );
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state: { pageIndex }
    } = useTable({ columns, data: data.trades }, useSortBy, usePagination);

    console.log(nameOptions)

    return (
        <div style={{ padding: '20px' }}>
            <h1 className="header">Congress Trades Report</h1>
            <Typography variant="body2" style={{ color: 'grey', marginBottom: '15px' }}>
                *If submit does not query correctly, please enter submit again or try a different ticker first.
            </Typography>
            <div style={{ marginBottom: '20px', display: 'flex', gap: '10px' }}>
                <Select
                    options={nameOptions}
                    value={name ? { value: name, label: name } : null}
                    onChange={(selectedOption) => setName(selectedOption.value)}
                    placeholder="Name"
                    isSearchable={true}
                    styles={{
                        control: provided => ({ ...provided, padding: '4px 8px' })
                    }}
                />
                <input value={ticker} onChange={(e) => setTicker(e.target.value)} placeholder="Ticker" style={{ padding: '8px' }} />
                <button onClick={fetchTrades} style={{ padding: '8px 15px', background: '#1976d2', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                    Search
                </button>
            </div>

            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                {data && data.trades && data.trades.length > 0 && (
                    <>
                        <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                            <div style={infoBoxStyle}>
                                <h4>Party Counts:</h4>
                                <p>Democrats: {data.party_counts.D}</p>
                                <p>Republicans: {data.party_counts.R}</p>
                            </div>
                            <div style={infoBoxStyle}>
                                <h4>Transaction Counts:</h4>
                                <p>Purchases: {data.transaction_count.Purchase}</p>
                                <p>Sales: {data.transaction_count.Sale}</p>
                            </div>
                        </div>

                        <table {...getTableProps()} style={tableStyle}>
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()} style={tableHeaderRowStyle}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())} style={tableHeaderCellStyle}>
                                                {column.render('Header')}
                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? ' 🔽'
                                                            : ' 🔼'
                                                        : ''}
                                                </span>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map(row => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()} onClick={() => handleRowClick(row.original)} style={tableRowStyle}>
                                            {row.cells.map(cell => (
                                                <td {...cell.getCellProps()} style={tableCellStyle}>{cell.render('Cell')}</td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
        
                        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <button onClick={previousPage} disabled={!canPreviousPage} style={paginationButtonStyle}>
                                Previous Page
                            </button>
                            <span>
                                Page {pageIndex + 1} of {pageOptions.length}
                            </span>
                            <button onClick={nextPage} disabled={!canNextPage} style={paginationButtonStyle}>
                                Next Page
                            </button>
                        </div>
                    </>
                )}

                {selectedTrade && (
                    <div style={{ border: '1px solid #e0e0e0', padding: '15px', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', margin: '15px 0' }}>
                        <h4>Trade Details</h4>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <span><strong>Representative:</strong> {selectedTrade.Representative}</span>
                            <span><strong>Party:</strong> {selectedTrade.Party}</span>
                            <span><strong>Transaction Type:</strong> {selectedTrade.Transaction}</span>
                            <span><strong>Amount:</strong> {selectedTrade.Amount}</span>
                            <span><strong>Value Range:</strong> {selectedTrade.Range}</span>
                            <span><strong>Report Date:</strong> {selectedTrade.ReportDate}</span>
                            <span><strong>Transaction Date:</strong> {selectedTrade.TransactionDate}</span>
                            <span><strong>Ticker:</strong> {selectedTrade.Ticker}</span>
                        </div>
                        <button style={{ marginTop: '15px', padding: '8px 15px', background: '#1976d2', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }} onClick={() => setSelectedTrade(null)}>Close Details</button>
                    </div>
                )}
                </>
            )}
        </div>
    );
}

const infoBoxStyle = {
    padding: '15px',
    border: '1px solid #e0e0e0',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    flex: 1
};

const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px'
};

const tableHeaderRowStyle = {
    borderBottom: '2px solid #1976d2'
};

const tableHeaderCellStyle = {
    padding: '10px',
    backgroundColor: '#f5f5f5',
    fontWeight: 'bold',
    textAlign: 'left'
};

const tableRowStyle = {
    cursor: 'pointer',
    transition: 'background-color 0.3s'
};

const tableCellStyle = {
    padding: '10px',
    borderBottom: '1px solid #e0e0e0'
};

const paginationButtonStyle = {
    padding: '8px 15px',
    background: '#1976d2',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer'
};

export default GovTrades;