import { React, useState } from 'react';
import { Container, Typography, Grid, Link, Box, Popover } from '@mui/material';
import './footer.css';

function AppFooter() {
  const [termsAnchorEl, setTermsAnchorEl] = useState(null);
  const [dataModelsAnchorEl, setDataModelsAnchorEl] = useState(null);

  const handleTermsPopoverOpen = (event) => {
    setTermsAnchorEl(event.currentTarget);
  };

  const handleTermsPopoverClose = () => {
    setTermsAnchorEl(null);
  };

  const handleDataModelsPopoverOpen = (event) => {
    setDataModelsAnchorEl(event.currentTarget);
  };

  const handleDataModelsPopoverClose = () => {
    setDataModelsAnchorEl(null);
  };

  const termsOpen = Boolean(termsAnchorEl);
  const dataModelsOpen = Boolean(dataModelsAnchorEl);

  return (
    <footer className='footer'>
      <Container maxWidth="lg">
        <Box py={4} textAlign="center" borderTop={1} borderColor="grey.200">
          <Grid container spacing={5} justifyContent="center">
            <Grid item xs={12} sm={4} className='footer-section'>
              <Typography variant="caption" display="block" gutterBottom>
                © 2024 Ease Of Finance
              </Typography>
              <Link href="mailto:conor.segreti@gmail.com" variant="caption">
                Contact Us
              </Link>
            </Grid>
            <Grid item xs={12} sm={4}>
            <Link variant="caption" onClick={handleTermsPopoverOpen} style={{ cursor: 'pointer' }}>
              Terms of Service
            </Link>
            <Popover
              open={termsOpen}
              anchorEl={termsAnchorEl}
              onClose={handleTermsPopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box p={2}>
                <Typography variant="body1">
                  Not Trading Advice, simple market, economic & stock analysis. You must use the app for lawful purposes only.
                </Typography>
                <Typography variant="body1">
                  This app is the property of Conor Segreti and models, data, or LMs used are property of licensors.
                </Typography>
                <Typography variant="body1">
                  Ease Of Finance shall not be liable for any decisions made based on information from this app.
                </Typography>
              </Box>
            </Popover>

          </Grid>
          <Grid item xs={12} sm={4} className="footer-section">
            <Link variant="caption" onClick={handleDataModelsPopoverOpen} style={{ cursor: 'pointer' }}>
              Models
            </Link>
            {' | '}
            <Link variant="caption" onClick={handleDataModelsPopoverOpen} style={{ cursor: 'pointer' }}>
              Data
            </Link>
            <Popover
              open={dataModelsOpen}
              anchorEl={dataModelsAnchorEl}
              onClose={handleDataModelsPopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Box p={2}>
                <Typography variant="body1">
                  Data provided by YFinance, QuiverQuant, Finnhub, and FRED
                </Typography>
                <Typography variant="body1">
                  Models Include Bert by Google, and GPT-4 by OpenAI
                </Typography>
                <Typography variant="body1">
                  Market data is delayed by at least 15 minutes, for some metrics last close.
                </Typography>
                <Typography variant="body1">
                  Economic Data Summarized from last three Years. 
                </Typography>
              </Box>
            </Popover>
              </Grid>
            </Grid> 
            <Box mt={2}>
            </Box>
          </Box>
      </Container>
    </footer>
  );
}

export default AppFooter;