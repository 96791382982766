import React from "react";
import { Link } from "react-router-dom";
import './App.css';

function TopBar() {

    return (
        <div className="navbar">
            <Link to="/AiChat">AI Analysis</Link>
            <Link to="QuantAlg">Quant Algorithms</Link>
            <Link to="/userReport">Stock Report</Link>
            <Link to="/gov-trades">Congress Trades</Link>
            <Link to="/contactUs">Contact Us</Link>
        </div>
    );
}

export default TopBar;