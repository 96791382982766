import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './cards.css'; 
import { motion } from 'framer-motion'

function updateScoreColor(score, elementSelector) {
    const scoreElement = document.querySelector(elementSelector);
    if (!scoreElement) return;

    const redIntensity = Math.round(255 * (1 - score / 10));
    const greenIntensity = Math.round(255 * (score / 10));

    const color = `rgb(${redIntensity}, ${greenIntensity}, 0)`;
    scoreElement.style.backgroundColor = color;
}

const Cards = () => {

    const [marketHealthScore, setMarketHealthScore] = useState(null)
    const [ecoHealthScore, setEcoHealthScore] = useState(null)

    const navigate = useNavigate(); 

    const navigateToAnalysis = () => {
        navigate('/eco_market_analysis'); 
    };

    useEffect(() => {
        fetch('https://api.easeoffinance.net/market_health') 
            .then(response => response.json())
            .then(data => {
                setMarketHealthScore(data.market_health_score);  
            })
            .catch(error => console.error('Error fetching market health score:', error));
    }, []);

    useEffect(() => {
        fetch('https://api.easeoffinance.net/eco_health') 
            .then(response => response.json())
            .then(data => {
                setEcoHealthScore(data.eco_health_score);  
            })
            .catch(error => console.error('Error fetching market health score:', error));
    }, []);

    useEffect(() => {
        if (marketHealthScore != null) {
            updateScoreColor(marketHealthScore, '.market_health .card_score');
        }
    }, [marketHealthScore]);
    
    useEffect(() => {
        if (ecoHealthScore != null) {
            updateScoreColor(ecoHealthScore, '.eco_health .card_score');
        }
    }, [ecoHealthScore]);

    return (
        <body class="Cardbody">
        <div class="cards">
        <article class="card eco_health">
            <div class="card_content">
                <h2 class="card_title">Economic Health</h2>
                <p class="card_address">
                    Current Economic Health:
                </p>
                <div class="card_bottom">
                        <div class="card_score">
                            {ecoHealthScore}
                        </div>
                    <button class="card_btn"></button>
                </div>
        
            </div>
            <p class="card_last_updated">Last Updated: This Morning as of 9:30AM</p> 
        </article>

        <article class="card market_health">
            <div class="card_content">
                <h2 class="card_title">Market Health</h2>
                <p class="card_address">
                    Current Market Health:
                </p>
                <div class="card_bottom">
                        <div class="card_score">
                            {marketHealthScore}
                        </div>
                    <button class="card_btn"></button>
                </div>
            </div>
            <p class="card_last_updated">Last Updated: This Morning as of 9:30AM</p> 
        </article>
        <article class="card analysis_card">
            <div class="card_content">
                <h2 class="card_title">Dive Deeper</h2>
                    <p class="card_address">
                        More detailed market and economic data analysis.
                    </p>
                    <button class="card_btn" onClick={navigateToAnalysis}>
                        <motion.span 
                            class="arrow-container"
                            whileHover={{ scale: 1.5 }} 
                            transition={{ type: "spring", stiffness: 300 }} 
                        >
                            →
                        </motion.span>
                    </button>
                </div>
        </article>

        </div>
    </body>
    );
}

export default Cards;
