import { React, useState, useEffect } from 'react';
import { Typography, Button, Grid, InputAdornment, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

function formatResponse(response) {
    const paragraphs = response.split('. ').map((sentence, index) => {
        if (index !== response.split('. ').length - 1) {
            return sentence + '.';
        }
        return sentence;
    });

    return paragraphs.map((paragraph, index) => (
        <Typography key={index} paragraph>
            {paragraph}
        </Typography>
    ));
}

function EcoMarketAnalysis () {
    const [chatMarketResponse, setChatMarketResponse] = useState(null);
    const [chatEcoResponse, setChatEcoResponse] = useState(null);
    const [analysisData, setAnalysisData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Fetch the analysis data from your Flask API
        fetch('https://api.easeoffinance.net/eco_market_analysis')
          .then(response => response.json())
          .then(data => {
            setAnalysisData(data);
          })
          .catch(error => console.error('Error fetching analysis data:', error));
      }, []);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch('https://api.easeoffinance.net/eco_market_analysis');
            console.log(response)
            const data = await response.json();
            if (data.market_health_analysis) {
                setChatMarketResponse(data.market_health_analysis);
                setChatEcoResponse(data.eco_health_analysis);
            } else {
                setChatMarketResponse("ERROR: Could not retrieve analysis");
                setChatEcoResponse("ERROR: Could not retrieve analysis")
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setChatMarketResponse("ERROR: Could not connect to the server");
            setChatEcoResponse("ERROR: Could not connect to the server")
        }
        setIsLoading(false);
    };
    console.log(analysisData)
    console.log(chatEcoResponse)
    console.log(chatMarketResponse)

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div style={{ padding: '20px' }}>
            <h1 className="header">Market and Economic Analysis</h1>
            <div>
                {isLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                    {chatMarketResponse && (
                        <div className="chatResponseContainer">
                            <Typography className='sub-header' variant="h6">AI Market Response</Typography>
                            <div className="chatResponseText">
                                {formatResponse(chatMarketResponse)}
                            </div>
                        </div>
                    )}
                    {chatEcoResponse && (
                        <div className="chatResponseContainer">
                            <Typography className='sub-header' variant="h6">AI Economic Response</Typography>
                            <div className="chatResponseText">
                                {formatResponse(chatEcoResponse)}
                            </div>
                        </div>
                    )}
                </>
                )}
            </div>
        </div>
    );
}

export default EcoMarketAnalysis;
