import React, { useState } from 'react';
import { Button, TextField, Box, FormControl, InputLabel, Select, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

function UserReport() { 
    const [ticker, setTicker] = useState("");
    const [downloadUrl, setDownloadUrl] = useState(null);
    const [reportOptions, setReportOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dcfParams, setDcfParams] = useState({
        growthRate: '',
        discountRate: '',
        terminalGrowthRate: '',
        years: ''
    });
    const [showDcfModal, setShowDcfModal] = useState(false);


    const handleReportOptionChange = (event) => {
        const {
            target: { value },
        } = event;
        setReportOptions(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const DcfModal = () => {
        const handleClose = () => {
            setShowDcfModal(false);
        };
    
        const handleInputChange = (e) => {
            setDcfParams({ ...dcfParams, [e.target.name]: e.target.value });
        };
    
        return (
            <Dialog open={showDcfModal} onClose={handleClose}>
                <DialogTitle>DCF Evaluation Parameters</DialogTitle>
                <DialogContent>
                    
                    <TextField
                        label="Growth Rate 5% expressed as 5"
                        name="growthRate"
                        value={dcfParams.growthRate}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        label="Discount Rate 5% expressed as 5"
                        name="discountRate"
                        value={dcfParams.discountRate}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        label="Terminal Growth Rate 5% expressed as 5"
                        name="terminalGrowthRate"
                        value={dcfParams.terminalGrowthRate}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        label="Years"
                        name="years"
                        value={dcfParams.years}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleDcfSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const includeDcf = window.confirm("Do you want to include a DCF analysis in the report?");
        if (includeDcf) {
            setShowDcfModal(true);
        } else {
            generateReport(false);
        }
    };
    
    const generateReport = (includeDcf) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                symbol: ticker,
                include_holders: reportOptions.includes('Holders'),
                include_quote: reportOptions.includes('Quote'),
                include_cashflow: reportOptions.includes('Cashflow'),
                include_balancesheet: reportOptions.includes('Balance'),
                include_incomestatement: reportOptions.includes('Income'),
                include_financial_ratios: reportOptions.includes('Ratios'),
                include_dcf: includeDcf,
                dcf_params: includeDcf ? dcfParams : {}, // Include the DCF parameters from state
            }),
        };
    
        fetch(`https://api.easeoffinance.net/userReport`, requestOptions)
        .then(response => response.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            setDownloadUrl(url);
            setLoading(false);  
        })
        .catch(error => {
            console.error("There was an error fetching the report:", error);
            setLoading(false);  
        });
    };

    const handleDcfSubmit = () => {
        setShowDcfModal(false);
        generateReport(true); 
    };

    return (
        <div style={{ padding: '20px' }}>
            <h1 className="header">Excel Stock Report</h1>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mt: 8,
                gap: 3,
                maxWidth: 400,
                margin: '0 auto'
            }}>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Stock Symbol"
                        variant="outlined"
                        fullWidth
                        value={ticker}
                        onChange={(e) => setTicker(e.target.value)}
                        placeholder="Enter Ticker"
                        required 
                        sx={{ 
                            borderRadius: 25, 
                            '& .MuiOutlinedInput-root': {
                                borderRadius: 25,
                            },
                        }}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="report-options-label">Report Options</InputLabel>
                        <Select
                            labelId="report-options-label"
                            multiple
                            value={reportOptions}
                            onChange={handleReportOptionChange}
                            renderValue={(selected) => selected.join(', ')}
                        >
                            <MenuItem value="Holders">Company Holders Report</MenuItem>
                            <MenuItem value="Quote">Company Quote Report</MenuItem>
                            <MenuItem value="Cashflow">Cashflow Statement</MenuItem>
                            <MenuItem value="Income">Income Statement</MenuItem>
                            <MenuItem value="Balance">Balance Sheet</MenuItem>
                            <MenuItem value="Ratios">Financial Ratios</MenuItem>
                        </Select>
                    </FormControl>
                    <Button 
                        type="submit" 
                        variant="contained" 
                        color="primary" 
                        fullWidth
                        size="large"
                        sx={{ 
                            borderRadius: 25, 
                            textTransform: 'none', 
                            fontSize: '1.1rem',
                            boxShadow: '0 4px 12px 0 rgba(0, 0, 0, 0.1)' 
                        }}
                    >
                        Generate Report
                    </Button>
                    {loading && <CircularProgress sx={{ mt: 2 }} />}
                </form>
                
                {downloadUrl && (
                    <Button 
                        variant="outlined" 
                        size="large"
                        fullWidth
                        sx={{ 
                            borderRadius: 25, 
                            textTransform: 'none', 
                            fontSize: '1.1rem' 
                        }}
                    >
                        <a 
                            href={downloadUrl} 
                            download={`${ticker}_report.xlsx`} 
                            style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                            Download Report
                        </a>
                    </Button>
                )}
            </Box>
            <DcfModal />
        </div>
    );
}

export default UserReport;